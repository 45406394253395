<template>
  <div class="content">
    <div class="media-top">
      <div class="container">
        <div class="row">
          <div class="col-lg-7 left">
            <div class="title">
              <!-- <img class="media-logo" src="@/assets/cover.png" alt="" /> -->
              <!-- <h1 class="top">DINOSAUR PLAN</h1> -->
              <img class="media-title" src="@/assets/dinoc-title.png" alt="" />
              <!-- <code>Address：0x56df7dd5e6476af94666e856c38aefc4d4555861</code> -->
            </div>
            <div class="media">
              DINOC is a decentralized blockchain digital asset issued by the
              decentralized community. Dinosaur Token on the Ethereum
              eco-chain,aims to provide power for the next generation of digital
              and biotech convergence. DINOC has connected many emerging
              technologies and biotechnology companies and is the only
              blockchain that can integrate the two into the protocol layer. In
              this way, emerging digital and biotech technologies can be
              connected through a variety of distributed applications (DAPP) to
              achieve true "virtual reality".
            </div>
          </div>
          <div class="col-lg-5 right">
            <img src="@/assets/dinoc.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div id="intro" class="media-intro">
      <div class="container">
        <div class="intro-top white-border">
          <h2 class="title">ABOUT DINOSAUR</h2>
          <div class="row">
            <div class="col-lg-6 left">
              <img src="@/assets/image1.jpeg" alt="" />
            </div>
            <div class="col-lg-6 right">
              <p class="intro">
                Dinosaur Token, or DINOC for short, is a decentralized digital
                asset issued based on the Ethereum ecological chain. Its purpose
                is to initiate a revolutionary experiment to create a green and
                environmentally-friendly integrated service platform, and
                ultimately to work with Elon Musk to create a real "Jurassic
                Park" together. The total circulation of DINOC is 1000 trillion,
                of which 50 percent have donated to the lab and the remaining
                have locked in Uniswap for liquidity.
              </p>
            </div>
          </div>
          <h2 class="title">WHY DINOC</h2>
          <div class="row">
            <p class="intro">
              DINOC was originally conceived by Elon Musk, who later founded
              Neuralink, an idea to bring real dinosaurs back to life. Neuralink
              is a "medical research" company founded in July 2016 by Elon Musk,
              who invested tens of millions of dollars, It's a company with a
              team of seven scientists and engineers. It aims to connect the
              human brain to machines by building micron-scale devices. Mark
              Hodak, Neuralink's co-founder, has said Mr. Musk's company has the
              technology to make a real-life version of Jurassic Park in the
              film. So DINOC will be used as admission or consumption tokens for
              the park after its completion!
            </p>
          </div>
        </div>
        <div class="media-cards">
          <div class="row">
            <div class="col-lg-4">
              <div class="card-item">
                <img src="@/assets/safe-box.png" alt="" />
                <p class="card-title">Unruggable</p>
                <p class="card-intro">
                  Liquidity is locked in UNISWAP.
                </p>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="card-item">
                <img src="@/assets/rise.png" alt="" />
                <p class="card-title">Continuously Rising Price Floor</p>
                <p class="card-intro">
                  A steady stream of community members join，creating an ever
                  rising price floor.
                </p>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="card-item">
                <img src="@/assets/quitable.png" alt="" />
                <p class="card-title">Fair Launch</p>
                <p class="card-intro">No allocation to team members.</p>
              </div>
            </div>
          </div>
        </div>

        <div id="buy" class="media-intro white-border">
          <h2 class="title">HOW TO BUY</h2>
          <div class="row">
            <div class="col-lg-6 left">
              <a
                style="text-align:center;"
                href="https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x56df7dd5e6476af94666e856c38aefc4d4555861"
                target="_blank"
              >
                <p>Click The Picture To Buy</p>
                <img src="@/assets/banner.png" alt="" />
              </a>
              <div>
                <p>DINOC TOKEN ADDRESS</p>
                <code @click="copyText($event)"
                  >0x56df7dd5e6476af94666e856c38aefc4d4555861</code
                >
              </div>
            </div>

            <div class="col-lg-6 right">
              <div v-for="(item, i) in list" :key="i" class="question-item">
                <div @click="toggle(i)" class="question-header">
                  <span>{{ item.label }}</span>
                  <span class="toggle">◢</span>
                  <!-- <img
                    :class="{ toggle: index == i + 1 }"
                    src="@/assets/right.png"
                    alt=""
                  /> -->
                </div>
                <div class="collapse collapse-item">
                  <div
                    v-if="i != 2"
                    v-html="item.intro"
                    class="card-body"
                  ></div>
                  <div v-else class="card-body">
                    Click Select a currency and enter this contract address into
                    the search field, and you should be able to find Dinosaur
                    Token.
                    <div></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
export default {
  data() {
    return {
      list: [
        {
          label: "Step 1",
          intro: "Download the app,Token Pocket,Purchase ETH or USDT",
        },
        {
          label: "Step 2",
          intro:
            "Go to the DApp tab, and find Uniswap. Your can also click the BUY NOW button on our website if you’re not on mobile.",
        },
        {
          label: "Step 3",
          intro:
            "Click Select a currency and enter this contract address <code style='color:#27df0e;font-weight:bold;'>0x56df7dd5e6476af94666e856c38aefc4d4555861</code> into the search field, and you should be able to find Dinosaur Token.",
        },
        {
          label: "Step 4",
          intro:
            "Now, when trading set slippage to 15%-20% and set the amount you want to purchase and press the swap button.Confirm the transaction.",
        },
      ],
      index: "",
    };
  },
  mounted() {
    $(".collapse-item").collapse("show");
    var url = window.location.href;
    if (url.split("/#")[1]) {
      var id = url.substring(url.lastIndexOf("#") + 1);
      document.getElementById(id).scrollIntoView(true);
      $("html body").animate({ scrollTop: id }, 1000); //1秒内完成
    }
  },
  methods: {
    toggle(i) {
      $(".collapse-item")
        .eq(i)
        .collapse("toggle");
    },
    // 复制文本
    copyText(event) {
      var tag = document.createElement("input");
      tag.setAttribute("id", "copyInput");
      tag.value = event.target.innerText;
      document.getElementsByTagName("body")[0].appendChild(tag);
      document.getElementById("copyInput").select();
      document.execCommand("copy");
      document.getElementById("copyInput").remove();
      alert("Copy succeeded ~");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
//Color palettes
$colors: (
  "surface": #303133,
  "primary": #fff,
);
@mixin color-var($property, $color) {
  #{$property}: map-get($colors, "#{$color}");
  #{$property}: var(--#{$color}, map-get($colors, "#{$color}"));
}
.row {
  margin: 0;
}
@-webkit-keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.content {
  padding-bottom: 120px;
  -webkit-animation-name: fadein;
  -webkit-animation-duration: 0.5s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: 1; /* 动画只播放一次 */
  .media-top {
    margin-top: -106px;
    padding-top: 100px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    // background: #000;
    // background-image: url("../assets/bg.png");
    // background-size: cover;
    // background-attachment: fixed;
    // background-position: 50%;
    .left {
      display: flex;
      flex-direction: column;
      justify-content: center;
      .title {
        text-align: center;
        // > h1 {
        //   font-size: 60px;
        //   font-style: oblique;
        //   font-weight: 700;
        //   color: #6cd85d;
        // }
        // code {
        //   border-radius: 6px;
        //   padding: 4px 8px;
        //   background: #fff;
        //   color: rgb(180, 30, 30);
        //   font-weight: bold;
        //   font-size: 15px;
        // }
        .media-logo {
          width: 128px;
        }
        .media-title {
          margin: 0 auto;
          display: block;
          width: 400px;
        }
      }
      .media {
        margin-top: 26px;
        text-align: center;
        font-size: 18px;
        line-height: 1.6;
        .row {
          width: 100%;
        }
      }
    }
    .right {
      > img {
        width: 450px;
      }
    }
  }
  #intro {
    width: 100%;
    // background-image: url("../assets/bg2.png");
    // background-size: cover;
    .white-border {
      border-radius: 8px;
      padding: 30px 60px;
      @include color-var(border, primary);
      border: 6px solid;
      margin-bottom: 120px;
      &:last-child {
        margin-bottom: 0px;
      }
      .title {
        word-wrap: break-word;
        margin: 40px 0;
        font-size: 36px;
        font-weight: bold;
        font-style: italic;
        text-align: center;
        position: relative;
        &::after {
          content: "";
          position: absolute;
          left: calc(50% - 50px);
          top: calc(100% + 20px);
          width: 100px;
          height: 1px;
          @include color-var(background-color, primary);
        }
      }
    }
  }
  .media-intro {
    padding: 60px 0;
    .intro-top {
      // background: #0f141a;
      font-size: 18px;
      line-height: 1.6;
      .row {
        padding: 60px 0;
        width: 100%;
        text-align: center;
        margin: 0;
        .left,
        .right {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .left {
          img {
            border-radius: 10px;
            width: 100%;
            margin: 15px 0;
          }
        }
      }
    }
    .media-cards {
      width: 100%;
      margin-bottom: 120px;
      .col-lg-4 {
        margin-bottom: 15px;
      }
      .card-item {
        height: 100%;
        border-radius: 6px;
        padding: 25px;
        width: 100%;
        background: #0f141ae8;
        display: flex;
        flex-direction: column;
        align-items: center;
        &:hover {
          background: #101822;
        }
        img {
          width: 36px;
        }
        .card-title {
          margin: 12px 0;
          color: #33b364;
          font-size: 18px;
          font-weight: 600;
          text-align: center;
        }
        .card-intro {
          font-size: 14px;
          font-weight: 400;
          text-align: center;
          color: #fff;
        }
      }
    }
  }
  #buy {
    width: 100%;
    .row {
      padding-top: 50px;
      .left {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        p {
          font-weight: bold;
          text-align: center;
        }
        > a {
          img {
            width: 100%;
            border-radius: 10px;
            margin-bottom: 36px;
          }
        }
        > div {
          padding: 0 20px;
          margin-bottom: 26px;
          text-align: center;
          code {
            transition: 0.2s;
            cursor: pointer;
            padding: 6px 10px;
            border: 2px solid;
            font-weight: bold;
            color: #33b364;
            @include color-var(border-color, primary);
            border-radius: 4px;
            &:hover {
              @include color-var(background-color, primary);
              @include color-var(color, surface);
            }
          }
        }
      }
      .right {
        display: flex;
        flex-direction: column;
        justify-content: center;
        .question-item {
          margin-bottom: 10px;
          width: 100%;
          .question-header {
            width: 100%;
            padding: 12px 15px;
            border: 1px solid transparent;
            // background: #f7f9fb;
            @include color-var(background-color, primary);
            @include color-var(color, surface);
            font-weight: 700;
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
            > img {
              width: 22px;
            }
            .toggle {
              transform: rotate(45deg);
            }
          }
        }
      }
    }
  }
  .text-special {
    display: inline-block;
    font-weight: bold;
  }
}
@media screen and (max-width: 992px) {
  .content .media-top {
    margin-top: 0px;
    padding-top: 60px;
    height: 100%;
  }
  .content .media-top .right img {
    width: 320px;
    display: block;
    margin: 0 auto;
  }
}
@media screen and (max-width: 600px) {
  .content {
    .media-top {
      padding-top: 80px;
      .title {
        > h1 {
          font-size: 36px;
        }
        code {
          display: none;
          font-size: 14px;
        }
        .media-logo {
          width: 84px;
        }
        .media-title {
          width: 300px;
        }
      }
      .media {
        font-size: 18px;
      }
    }
  }
  #intro .white-border {
    padding: 30px 15px !important;
  }
}
@media screen and (max-width: 500px) {
  .content .media-top .left .title .media-title {
    width: 300px;
  }
  code{
    font-size: 12px;
  }
}
@media screen and (max-width: 380px) {
  .content {
    .media-top {
      padding-top: 80px;
      .media {
        font-size: 15px;
      }
    }
  }
}
</style>
